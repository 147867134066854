import $ from 'jquery';
import 'what-input';
import device from "current-device";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//let lottie = require('lottie-web');

import {
  Swiper,
  Navigation,
  Pagination,
  Scrollbar,
  Controller,
  A11y,
  EffectFade
} from "swiper";
Swiper.use([Navigation, Pagination, Scrollbar, A11y, Controller, EffectFade]);

$(document).foundation();

// let element = document.getElementById('elia-anim');
// lottie.loadAnimation({
//   container: element, // the dom element that will contain the animation
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   path: element.getAttribute('data-animation-path'), // the path to the animation json
//   rendererSettings: {
//     filterSize: {
//       width: '200%',
//       height: '200%',
//       x: '-50%',
//       y: '-50%',
//     }
//   }
// });

const swiper = new Swiper(".swiper--team", {
  slidesPerView: "auto",
  centeredSlides: false,
  spaceBetween: 2,
  breakpoints: {
    860: {
      spaceBetween: 30,
      slidesPerView: 3,
      centeredSlides: false,
      loop: true
    }
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-controls--team .swiper-button--next',
  },
});

$(function () {
  $("a[href*='#']:not([href='#'])").click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 90
        }, 1000);
        return false;
      }
    }
  });
});

$('.off-canvas a').on('click', function () {
  $('.off-canvas').foundation('close');
});

// Newsletter form
const $newsletterForm = $(".form-newsletter");
$newsletterForm.on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);
  const $formMessages = $form.find(".form-response");
  const $button = $form.find("button");

  $button.attr("disabled", true).addClass("is-loading");
  $formMessages.html("");

  $.ajax({
      type: "post",
      url: $form.attr("action"),
      data: $form.serialize()
    })
    .done(function (data) {
      $button.attr("disabled", false).removeClass("is-loading");
      $form[0].reset();

      console.log(data);

      // Set the message text.
      if (data && data !== "") {
        $formMessages.html('<div class="callout success">' + data + '</div>');
      }
    })
    .fail(function (data) {
      $button.attr("disabled", false).removeClass("is-loading");

      // Set the message text.
      if (data.responseText && data.responseText !== "") {
        $formMessages.html(data.responseText);
        $formMessages.html('<div class="callout alert">' + data.responseText + '</div>');
      } else {
        $formMessages.html('<div class="callout alert">There was a problem while subscribing, please try again.</div>');
      }
    });

  return false;
});

// Mobile device detection
let platform = "Web";
if (device.os === "android") {
  platform = "Android";
}
if ($.inArray(device.os, ["ios", "iphone", "ipad", "ipod"]) !== -1) {
  platform = "iOS";
}

//set visibility to CTA button based on device detection
const dynamicButtons = document.getElementById('download-buttons');
dynamicButtons.getElementsByClassName("download-buttons__item")[0].classList.remove('visible');

switch (platform) {
  case 'Android':
    dynamicButtons.getElementsByClassName("android")[0].classList.add('visible');
    break;

  case 'iOS':
    dynamicButtons.getElementsByClassName("ios")[0].classList.add('visible');
    break;

  case 'Web':
    dynamicButtons.getElementsByClassName("web")[0].classList.add('visible');
    break;

  default:
    break;
}